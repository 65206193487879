import React, { useState, useContext, useEffect } from 'react';
import { useToast, Box, Text, Textarea, Button, Grid, GridItem, Flex, Image,
    Input,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,} from "@chakra-ui/react";
import {  UnorderedList, ListItem, Code, VStack, HStack } from '@chakra-ui/react';
import { callApi } from '../callApi'; // Adjust the import path as necessary
import { AppContext } from './AppContext';
import HeaderMenus from './HeaderMenus'; // Adjust the import path as necessary
import ReactMarkdown from 'react-markdown';

function IonmText() {
  const [ionmText, setIonmText] = useState('');
  const [processing, setProcessing] = useState(false);
  const [parsedResults, setParsedResults] = useState(null);
  const [cptCodesInput, setCptCodesInput] = useState('');
  const [icd10CodesInput, setIcd10CodesInput] = useState('');
  const [relevancyResults, setRelevancyResults] = useState(null);
  const [file, setFile] = useState(null); // New state variable for the file
  const [cases, setCases] = useState([]); // New state variable for the list of cases


  const { accessToken } = useContext(AppContext);
  const toast = useToast();

  let noIcdList = null;

  const LinkRenderer = ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
      {children}
    </a>
  );


  useEffect(() => {
    // Function to fetch cases
    const fetchCases = async () => {
      try {
        const response = await callApi('/get_ionm_cases', accessToken);
        const data = await response.json();
        if (data && data.cases) {
          setCases(data.cases); // Update the state with the fetched cases
        }
      } catch (error) {
        console.error('Error fetching cases:', error);
        toast({
          title: 'Fetching cases failed.',
          description: "There was an error fetching the cases.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchCases(); // Call the function to fetch cases when the component mounts
  }, [accessToken, toast]); // Dependencies array

  const handleCaseClick = async (caseId) => {
    setProcessing(true); // Indicate that processing has started

    try {
      // Make an API call to the backend with the case_id
      const response = await callApi('/process_ionm_pdf', accessToken, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ case_id: caseId }),
      });

      const data = await response.json();      
      setRelevancyResults(data); 

      console.log('Processing result:', data);

      toast({
        title: 'Case processed.',
        description: "The case has been successfully processed.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error processing case:', error);
      toast({
        title: 'Processing failed.',
        description: "There was an error processing the case.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false); // Indicate that processing has ended
    }
  };



  const renderCasesList = () => {
    return (
      <Box borderWidth="1px" borderRadius="lg" p={4} mb={4}>
        <Text fontSize="xl" fontWeight="bold" mb={2}>
          Cases:
        </Text>
        <UnorderedList>
          {cases.map((caseItem) => (
            <ListItem key={caseItem.id} cursor="pointer" _hover={{ bg: "gray.100" }} onClick={() => handleCaseClick(caseItem.id)}>
              {caseItem.case_name} (ID: {caseItem.id})
            </ListItem>
          ))}
        </UnorderedList>
      </Box>
    );
  };



  const renderRelevancyResults = () => {
    if (relevancyResults && typeof relevancyResults === 'object') {
      console.log('Relevancy results:', relevancyResults);

      const { icd10_overview: icd10Overview, icd10_cpt: icd10Cpt } = relevancyResults;
  
      const { icd10_codes: icd10Codes, cpt_codes: cptCodes, relevancy_check_result: relevancyCheckResult } = icd10Cpt;
  
      console.log('ICD-10 codes:', icd10Codes);
      console.log('CPT codes:', cptCodes);
      console.log('Relevancy check result:', relevancyCheckResult);

      if (relevancyCheckResult && typeof relevancyCheckResult === 'object' && Object.keys(relevancyCheckResult).length > 0) {
        // Find CPT codes with no associated ICD-10 codes
        const cptCodesWithNoIcd = Object.entries(relevancyCheckResult)
          .filter(([cptCode, relevancies]) => relevancies.length === 0)
          .map(([cptCode]) => cptCode);

        // Render the list of CPT codes with no associated ICD-10 codes
        noIcdList = cptCodesWithNoIcd && cptCodesWithNoIcd.length > 0 && (
          <Box borderWidth="1px" borderRadius="lg" p={4} mb={4}>
            <Text fontSize="sm" color="red.500" mb={2}>
              No ICD-10 codes for the following CPT codes:
            </Text>
            <UnorderedList>
              {cptCodesWithNoIcd.map(cptCode => (
                <ListItem key={cptCode}>{cptCode}</ListItem>
              ))}
            </UnorderedList>
          </Box>
        );
      }
      else {
        noIcdList = null;
      }


      console.log('ICD-10 overview:', icd10Overview);

      const icd10DetailsList = icd10Codes && Object.entries(icd10Codes).length > 0 && (
        <Grid templateColumns="repeat(1fr, 1fr)" gap={4}>
          {
            // Step 1: Convert the object to an array of entries and sort them by match score
            Object.entries(icd10Codes)
            .sort((a, b) => {
              // Extract match scores, default to 0 if undefined
              const matchScoreA = a[1].match || 0;
              const matchScoreB = b[1].match || 0;
              // Compare for descending order
              return matchScoreB - matchScoreA;
            })
            // Step 2: Map the sorted entries to JSX elements
            .map(([codeKey, codeDetails]) => (
              <Box key={codeKey} borderWidth="1px" borderRadius="lg" p={2} boxShadow="md" bg="white">
                <VStack alignItems="stretch" spacing={3}>
                <Box>
                  <Text fontSize="sm" fontWeight="bold">
                     {codeKey}
                  </Text>
                  {codeDetails['icd-10 code'] && (
                    <Text fontSize="sm" color="gray.600">
                      {codeDetails['icd-10 code'].text}
                    </Text>
                  )}
                </Box>

                <HStack spacing={3}>
          
                  <Box borderWidth="1px" borderRadius="md" p={3}>
                    {codeDetails['diagnosis text'] && codeDetails['diagnosis text'].texts && (
                      <Box>
                        <Text fontSize="sm" fontWeight="semibold" mb={1}>
                        Diagnosis Text:
                        </Text>
                        <UnorderedList fontSize="sm" spacing={1}>
                          {Object.entries(codeDetails['diagnosis text'].texts).map(([diagnosisText, textRange]) => (
                            <ListItem key={diagnosisText}>
                              <Text>{diagnosisText}</Text>
                              <Text fontSize="xs" color="gray.500">
                                (Start: {textRange.start}, End: {textRange.end})
                              </Text>
                            </ListItem>
                          ))}
                        </UnorderedList>
                      </Box>
                    )}
                  </Box>
                
                  <Box borderWidth="1px" borderRadius="md" p={2} flexGrow={1}>
                    
                    {codeDetails.match && (
                      <Text fontSize="sm" mb={1}>
                        Match Score: {codeDetails.match}
                      </Text>
                    )}

                  </Box>
                </HStack>
      

              </VStack>
            </Box>
          ))}
        </Grid>
      );

      console.log('ICD-10 overview:', Object.entries(icd10Overview).length);
      console.log(JSON.stringify(icd10Overview, null, 2)) 
      const icd10OverviewList = icd10Overview && Object.entries(icd10Overview).length > 0 && (
        <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={4}>
          {Object.entries(icd10Overview)
            .sort(([, a], [, b]) => (b.primary ? 1 : 0) - (a.primary ? 1 : 0))
            .map(([icd10Code, codeDetails]) => (
              <Box key={icd10Code} borderWidth="1px" borderRadius="lg" p={2} boxShadow="md" bg="white">
                <VStack alignItems="stretch" spacing={3}>
                  <Box>
                    <Text fontSize="sm" fontWeight="bold">
                      {icd10Code}
                    </Text>
                  </Box>
    
                  <Box borderWidth="1px" borderRadius="md" p={3}>
                    <Text fontSize="sm" fontWeight="semibold" mb={1}>
                      Covered:
                    </Text>
                    <UnorderedList fontSize="sm" spacing={1}>
                      {codeDetails.cpt_codes_covered.map(cptCode => (
                        <ListItem key={cptCode}>{cptCode}</ListItem>
                      ))}
                    </UnorderedList>
                  </Box>
    
                  <Box borderWidth="1px" borderRadius="md" p={3}>
                    <Text fontSize="sm" fontWeight="semibold" mb={1}>
                      Not Covered:
                    </Text>
                    <UnorderedList fontSize="sm" spacing={1}>
                      {codeDetails.cpt_codes_not_covered.map(cptCode => (
                        <ListItem key={cptCode}>{cptCode}</ListItem>
                      ))}
                    </UnorderedList>
                  </Box>
                </VStack>
              </Box>
            ))}
        </Grid>
      );
    



      const cptList = cptCodes && Object.keys(cptCodes).length > 0 && (
        <Box borderWidth="1px" borderRadius="lg" p={4} mb={4} boxShadow="md" bg="white">
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            CPT Codes:
          </Text>
          <VStack spacing={3} alignItems="stretch">
            {Object.entries(cptCodes).map(([cptCode, description]) => (
              <Box key={cptCode} p={2} borderWidth="1px" borderRadius="md" mb={2} _hover={{ bg: "gray.100" }}>
                <HStack spacing={3}>
                  <Text p={2} fontSize="md">
                    {cptCode}
                  </Text>
                  <Text fontSize="md">{description}</Text>
                </HStack>
              </Box>
            ))}
          </VStack>
        </Box>
      );
  
      const uniquePairs = {};
  
      const relevancyTable = (
        <Box borderWidth="1px" borderRadius="lg" p={4} mb={4}>
          <Text fontSize="xl" fontWeight="bold" mb={2}>
            Relevancy Table:
          </Text>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>CPT Code</Th>
                <Th>ICD-10 Code</Th>
                <Th>Relevancy Score</Th>
                <Th>Justification</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.entries(relevancyCheckResult).flatMap(([cptCode, relevancies]) => {
                console.log(`Processing CPT Code: ${cptCode}`);
  
                if (!Array.isArray(relevancies)) {
                  console.error(`Expected an array for CPT code ${cptCode}, but received:`, relevancies);
                  return []; // Return an empty array to continue processing other entries
                }
  
                return relevancies.filter(relevancy => {
                  if (!relevancy || !relevancy.icd10_code) {
                    console.error(`Invalid relevancy object encountered for CPT code ${cptCode}:`, relevancy);
                    return false; // Skip invalid relevancy objects
                  }
  
                  const pairKey = `${cptCode}-${relevancy.icd10_code}`;
                  if (!uniquePairs[pairKey]) {
                    uniquePairs[pairKey] = true; // Mark this pair as seen
                    console.log(`Including relevancy for pair: ${pairKey}`);
                    return true; // Include this relevancy in the table
                  }
                  console.log(`Excluding duplicate relevancy for pair: ${pairKey}`);
                  return false; // Exclude duplicate relevancy from the table
                }).map((relevancy, index) => (
                  <Tr key={`${cptCode}-${relevancy.icd10_code}-${index}`}>
                    <Td>{cptCode}</Td>
                    <Td>{relevancy.icd10_code}</Td>
                    <Td>{relevancy.relevancy_score}</Td>
                    <Td>
                    <details>
      <summary>View Details</summary>
      <ReactMarkdown
        components={{
          a: LinkRenderer,
        }}
      >
        {relevancy.relevancy_source}
      </ReactMarkdown>
          </details>
          </Td>
                  </Tr>
                ));
              })}
            </Tbody>
          </Table>
        </Box>
      );
  
      return (
        <VStack alignItems="stretch" spacing={4}>
          {noIcdList}
          {icd10DetailsList}
          {cptList}
          overview list
          {icd10OverviewList}
          {relevancyTable}
        </VStack>
      );
    }
    return <Text>No relevancy results to display.</Text>;
  };


  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Set the selected file into state
  };

  const handleFileUpload = async () => {
    if (!file) {
      toast({
        title: 'No file selected.',
        description: "Please select a PDF file to upload.",
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setProcessing(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await callApi('/process_ionm_pdf', accessToken, {
        method: 'POST',
        body: formData, // Send the file as FormData
      });

      const data = await response.json();
      setRelevancyResults(data); 

      toast({
        title: 'Processing successful.',
        description: "The text has been processed.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error processing ionm text:', error);
      toast({
        title: 'Processing failed.',
        description: "There was an error processing your text.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }


  };



  return (
    <Box fontSize="sm">
      <Grid
        templateAreas={`"header header"
                        "main main"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontWeight='regular'
      >
        {/* Header with menu */}
        <HeaderMenus />

        <GridItem bg='white' padding={4} area={'main'}>
          <Box fontSize="sm">
          {renderCasesList()}

            <HStack spacing={4} mb={4}>
          <Input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            disabled={processing}
            width={64}
          />
          <Button
            mt="4"
            onClick={handleFileUpload}
            disabled={processing || !file}
          >
            {processing ? 'Processing...' : 'Upload PDF'}
          </Button>
        </HStack>

            {relevancyResults && renderRelevancyResults()}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default IonmText;