import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Menu, MenuButton, MenuList, MenuItem, Text, Spinner, MenuDivider,GridItem } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useNavigate } from 'react-router-dom';
import { Flex, Image } from "@chakra-ui/react";
import { useFeatureFlags } from './FeatureFlags';  // Import your useFeatureFlags hook
import { ThemeProvider, CSSReset, ColorModeProvider } from "@chakra-ui/react";
import { nmaTheme, revedyTheme, theosTheme } from '../themes';  // Import your themes
import CapabilitiesMenu from "./CapabilitiesMenu";



const AccountMenu = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return isAuthenticated ? (
    <Menu>
      <MenuButton as={Text} righticon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
        Account
      </MenuButton>
      <MenuList>
        <MenuItem isDisabled fontSize="sm" color="black">{user.email}</MenuItem>
        <MenuItem onClick={handleLogout} fontSize="sm" color="black">Log Out</MenuItem>
      </MenuList>
    </Menu>
  ) : 
  (  <Menu>
  <MenuButton as={Text} righticon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
    Get Started
  </MenuButton>
  <MenuList>
    <MenuItem isDisabled fontSize="sm" color="black">Login Required</MenuItem>
  </MenuList>
</Menu>
)
  ;
};

const CodeMenu = () => {
  const navigate = useNavigate();

  const handleItemClick = (path) => {
    navigate(path);
  };

  return (
    <Menu>
      <MenuButton as={Text} righticon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
        Code
      </MenuButton>
      <MenuList>
        <MenuDivider />
        <MenuItem fontSize="sm" color="black" isDisabled>
          Code
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/note')}>
          Code Encounter Note
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/ionmform')}>
          Code IOM Data
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const IONMCodeMenu = () => {
  const navigate = useNavigate();

  const handleItemClick = (path) => {
    navigate(path);
  };

  return (
    <Menu>
      <MenuButton as={Text} righticon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
        IONM Cases
      </MenuButton>
      <MenuList>
        <MenuDivider />
        <MenuItem fontSize="sm" color="black" isDisabled>
          Code
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/ionmform')}>
          IONM Case List
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/ionmtable')}>
          IONM Case Table
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/analytics')}>
          Integration Status
        </MenuItem>
      </MenuList>
    </Menu>
  );
};


const GenerateMenu = () => {
  const navigate = useNavigate();

  const handleItemClick = (path) => {
    navigate(path);
  };

  return (
    <Menu>
      <MenuButton as={Text} righticon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
        Generate
      </MenuButton>
      <MenuList>
        <MenuDivider />
        <MenuItem fontSize="sm" color="black" isDisabled>
          Generate
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/generate')}>
          Generate Note for Review
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/prior')}>
          Prior Auth (beta)
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const GeneralMenu = () => {
  const navigate = useNavigate();

  const handleItemClick = (path) => {
    navigate(path);
  };

  return (
    <Menu>
      <MenuButton as={Text} righticon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
        Conversations
      </MenuButton>
      <MenuList>
        <MenuDivider />
        <MenuItem fontSize="sm" color="black" isDisabled>
          General
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/chat')}>
          Chat
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const TranscribeMenu = () => {
  const navigate = useNavigate();

  const handleItemClick = (path) => {
    navigate(path);
  };

  return (
    <Menu>
      <MenuButton as={Text} righticon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
        Scribe
      </MenuButton>
      <MenuList>
        <MenuDivider />
        <MenuItem fontSize="sm" color="black" isDisabled>
          Transcribe
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/transcribe')}>
          Transcribe
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const OtherMenu = () => {
  const navigate = useNavigate();

  const handleItemClick = (path) => {
    navigate(path);
  };

  return (
    <Menu>
      <MenuButton as={Text} fontSize="sm" textDecoration="underline" color="menu_text_primary">
        More
      </MenuButton>
      <MenuList>
        <MenuDivider />
        <MenuItem fontSize="sm" color="black" isDisabled>
          Other
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/transition')}>
          Care Transitions (beta)
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/denials')}>
          Denials (beta)
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/CPT2024')}>
          CPT 2024 changes (beta)
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const FeaturesMenu = () => {
  return (
    <Flex style={{ gap: '20px' }}>
      <CodeMenu />
      <GenerateMenu />
      <GeneralMenu />
      <TranscribeMenu />
      <OtherMenu />
    </Flex>
  );
}; 

const MenuVersionDefaultOLD = () => {
return ( <GridItem
      bg='black'
      borderBottom='0px'
      borderColor='brand.200'
      paddingY={4}
      paddingX={6}
      area={'header'}
    >
      <Flex justifyContent="space-between" alignItems="center" width="100%" height="100%">
      <Image src="/images/revedy_logo.png" alt="Revedy" height={["15px"]} />
      <Flex style={{ gap: '20px' }}>
        <CodeMenu />
        <GenerateMenu />
        <GeneralMenu />
        <TranscribeMenu />
        <OtherMenu />
      </Flex>
      <AccountMenu />
    </Flex>
    </GridItem>
 );

};

const MenuVersionDefaultNov2023 = () => {
  return (
  <GridItem
  bg='header_background'
  borderBottom='1px'
  borderColor='brand.200'
  paddingY={4}
  paddingX={6}
  area={'header'}
>

  <Flex justifyContent="space-between" alignItems="center" width="100%" height="100%">
  <Image src="/images/revedy_logo.png" alt="Revedy" height={["15px"]} />
    <Flex style={{ gap: '20px' }}>
      <CodeMenu />
      <GenerateMenu />
      <GeneralMenu />
      <TranscribeMenu />
      <OtherMenu />
    </Flex>
    <AccountMenu />
  </Flex>
</GridItem>
  );
};





const MenuVersionNMA = () => {
  return (
  <GridItem
  bg='header_background'
  borderBottom='0px'
  borderColor='brand.200'
  paddingY={4}
  paddingX={6}
  area={'header'}
>

  <Flex justifyContent="space-between" alignItems="center" width="100%" height="100%">
    <Image src="/images/nma_logo.png" alt="NMA" height={["35px"]} />
    <Flex style={{ gap: '20px' }}>
      <IONMCodeMenu />
      <CodeMenu />
      <GenerateMenu />
      <GeneralMenu />
      <TranscribeMenu />
      <OtherMenu />
    </Flex>
    <AccountMenu />
  </Flex>
</GridItem>
  );
};


const MenuVersionDefault = () => {
  const navigate = useNavigate();

  const handleItemClick = (path) => {
    navigate(path);
  };

  return (
    <GridItem
      bg='header_background'
      borderBottom='1px'
      borderColor='brand.200'
      paddingY={4}
      paddingX={6}
      area={'header'}
    >
      <Flex justifyContent="space-between" alignItems="center" width="100%" height="100%">
        <Image src="/images/revedy_logo.png" alt="Revedy" height={["15px"]} onClick={() => handleItemClick('/home')} />
        <Flex style={{ gap: '20px' }}>
        <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/home')}>
            Home
          </Text>
          <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/note')}>
            Code Notes
          </Text>
          <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/generate')}>
            Create Notes
          </Text>
          <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/transcribe')}>
            Scribe Services
          </Text>
          <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/chat')}>
            Healthcare AI Chat
          </Text>
          <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/ionmform')}>
            Code IONM CPT
          </Text>
          <Menu>
      <MenuButton as={Text} righticon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
        More
      </MenuButton>
      <MenuList>
        <MenuDivider />
        <MenuItem fontSize="sm" color="black" isDisabled>
          Other
        </MenuItem>
        {/* Add Integration Services as a new MenuItem */}
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/analyticsdemo')}>
          Integration Services
        </MenuItem>
        {/* Move the Coming Soon text into a MenuItem */}
        <MenuItem pl={5} fontSize="sm" textDecoration="underline" color="menu_text_secondary" isDisabled>
          More Features Coming Soon
        </MenuItem>
        {/* Existing menu items */}
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/transition')}>
          Care Transitions (beta)
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/denials')}>
          Denials (beta)
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/CPT2024')}>
          CPT 2024 changes (beta)
        </MenuItem>
      </MenuList>
    </Menu>



        </Flex>
        <AccountMenu />
      </Flex>
    </GridItem>
  );
};

const MenuVersionTheos = () => {
  const navigate = useNavigate();

  const handleItemClick = (path) => {
    navigate(path);
  };

  return (
    <GridItem
      bg='header_background'
      borderBottom='1px'
      borderColor='brand.200'
      paddingY={4}
      paddingX={6}
      area={'header'}
    >
      <Flex justifyContent="space-between" alignItems="center" width="100%" height="100%">
        <Image src="/images/theos_logo.png" alt="Theos" height={["55px"]} onClick={() => handleItemClick('/home')} />
        <Flex style={{ gap: '20px' }}>
        <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/home')}>
            Home
          </Text>
          <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/note')}>
            Code Notes
          </Text>
          <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/generate')}>
            Create Notes
          </Text>
          <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/transcribe')}>
            Scribe Services
          </Text>
          <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/chat')}>
            Healthcare AI Chat
          </Text>
          <Text fontSize="sm" textDecoration="underline" color="menu_text_primary" cursor="pointer" onClick={() => handleItemClick('/ionmform')}>
            Code IONM CPT
          </Text>
          <Menu>
      <MenuButton as={Text} righticon={<ChevronDownIcon />} fontSize="sm" textDecoration="underline" color="menu_text_primary">
        More
      </MenuButton>
      <MenuList>
        <MenuDivider />
        <MenuItem fontSize="sm" color="black" isDisabled>
          Other
        </MenuItem>
        {/* Add Integration Services as a new MenuItem */}
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/analyticsdemo')}>
          Integration Services
        </MenuItem>
        {/* Move the Coming Soon text into a MenuItem */}
        <MenuItem pl={5} fontSize="sm" textDecoration="underline" color="menu_text_secondary" isDisabled>
          More Features Coming Soon
        </MenuItem>
        {/* Existing menu items */}
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/transition')}>
          Care Transitions (beta)
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/denials')}>
          Denials (beta)
        </MenuItem>
        <MenuItem pl={5} fontSize="sm" color="black" onClick={() => handleItemClick('/CPT2024')}>
          CPT 2024 changes (beta)
        </MenuItem>
      </MenuList>
    </Menu>



        </Flex>
        <AccountMenu />
      </Flex>
    </GridItem>
  );
};


const menuVersions = {
  'default': MenuVersionDefault,
  'nma': MenuVersionNMA,
  'theos': MenuVersionTheos,
};

const HeaderMenus = () => {
  
  const { featureFlags } = useFeatureFlags();  // Get the feature flags from your context
  let MenuComponent;
  // Check if featureFlags is set and contains a menu_version property
  if (featureFlags && featureFlags['menu_version']) {
    MenuComponent = menuVersions[featureFlags['menu_version']];  // Get the component for the current version
  } else {
    MenuComponent = 'default';
  }
  

  let theme;
  if (!featureFlags || !featureFlags['menu_version']) {
    theme = revedyTheme;
  }
  else {
    switch (featureFlags['menu_version']) {
      case 'default':
        theme = revedyTheme
        break;
      case 'nma':
        theme = nmaTheme;
        break;
      case 'theos':
        theme = theosTheme;
        break;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <ColorModeProvider>
        <MenuComponent />
      </ColorModeProvider>
    </ThemeProvider>
  );
};  

export default HeaderMenus;
export { MenuVersionDefault };