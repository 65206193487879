// APIDocs.js
import { Box, Grid, GridItem, Text, VStack, Link, Divider, Heading, Code, Container,Button, Spinner } from "@chakra-ui/react";
import HeaderMenus from './HeaderMenus'; // Adjust the import path based on your project structure
import React, { useState, useContext } from 'react';
import { AppContext } from '../components/AppContext'; // Adjust the import path based on your project structure
import { callApi } from '../callApi'; 

function APIDocs() {
    const { accessToken } = useContext(AppContext);
    const [credentials, setCredentials] = useState(null);
    const [loadingCredentials, setLoadingCredentials] = useState(false);

  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };
  const handleGetCredentialsClick = async () => {
    setLoadingCredentials(true);
    try {
      const response = await callApi('/getcredentials', accessToken, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setCredentials(data);
    } catch (error) {
      console.error("Error fetching credentials: ", error);
      // Handle errors as appropriate
    }
    setLoadingCredentials(false);
  };

  return (
    <Box textAlign="center" fontSize="xl">
        <Grid
        templateAreas={`"header header"    
                        "main results"
                        "footer footer"`}
        gridTemplateRows={'60px 1fr 60px'}
        gridTemplateColumns={'250px 4fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontWeight='bold'
        >
                {/* Header with menu */}
                    <HeaderMenus />

        {/* Navigation sidebar */}
        <GridItem area="main" bg='white' p={3} overflowY="auto" height="500px">
            <br/>
            
  <VStack textAlign="left" align="start" spacing={3}>
    //header text saying Revedy API documentation
    <Heading as="h3" size="m">Revedy API Documentation</Heading>
    <Divider />
    <Link fontSize="sm" fontWeight="normal" href="#overview" onClick={(e) => scrollToSection(e, 'overview')}>Overview</Link>
    <Link fontSize="sm" fontWeight="normal" href="#authentication" onClick={(e) => scrollToSection(e, 'authentication')}>Authentication</Link>
    <Link fontSize="sm" fontWeight="normal" href="#endpoints" onClick={(e) => scrollToSection(e, 'endpoints')}>Endpoints</Link>
      <VStack textAlign="left" align="start" spacing={1} pl={4}>
        <Link fontSize="sm" fontWeight="normal" href="#get-recent-notes" onClick={(e) => scrollToSection(e, 'get-recent-notes')}>Get Recent Notes</Link>
        <Link fontSize="sm" fontWeight="normal" href="#encounter-analysis" onClick={(e) => scrollToSection(e, 'encounter-analysis')}>Encounter Analysis</Link>
        <Link fontSize="sm" fontWeight="normal" href="#generate-note-from-data" onClick={(e) => scrollToSection(e, 'generate-note-from-data')}>Generate Note from Data</Link>
        {/* Add more endpoint links as needed */}
      </VStack>
    <Link fontSize="sm" fontWeight="normal" href="#error-codes" onClick={(e) => scrollToSection(e, 'error-codes')}>Error Codes</Link>
    <Link fontSize="sm" fontWeight="normal" href="#rate-limits" onClick={(e) => scrollToSection(e, 'rate-limits')}>Rate Limits</Link>
    {/* Add more navigation links as needed */}
  </VStack>
</GridItem>

        <GridItem area="results" bg='white' p={6} ml="30px" overflowY="auto" height="700px" width="1000px">
            <VStack spacing={8} textAlign="left" align="start">
            <Box id="overview">
                <Heading as="h2" size="lg" mb={2}>Overview</Heading>
                <Text fontSize="md" >
                    The Revedy API provides a secure way for partners to programmatically access our services. Authentication is handled through a standard OAuth 2.0 process, where clients are issued tokens that grant temporary access to the API.
                </Text>
                </Box>
                <Divider />

                <Box id="authentication">
                    <Heading as="h2" size="lg" mb={2}>Authentication</Heading>
                    <Text fontSize="md" >
                    To access the API, clients must first obtain an access token by providing their unique client credentials. This token is then used in the Authorization header as a Bearer token to authenticate API requests.
                </Text>
                <Text fontSize="md" >
                    All interactions with the API should be conducted over HTTPS to ensure the confidentiality and integrity of the data transmitted.
                </Text>
                <Text fontSize="md" >
                    The base URL for all API endpoints is <Code>https://api.revedy.io</Code>. Below is an example of how to obtain an access token and make a request to the API.
                </Text>
                <Text fontSize="md">
                    Example request to obtain an access token:
                </Text>
                <Code p={2} bg="gray.100"  whiteSpace="pre" children=
{`curl --request POST \\
--url 'https://demo-login.revedy.io/oauth/token' \\
--header 'content-type: application/json' \\
--data '{
    "client_id": "YOUR_CLIENT_ID",
    "client_secret": "YOUR_CLIENT_SECRET",
    "audience": "https://revedy.io/demo",
    "grant_type": "client_credentials"
}'`} />
<Text fontSize="md" mt={4}>
                    Example request to the <Code>/getrecentnotes</Code> endpoint using the access token:
                </Text>
                <Code whiteSpace="pre" p={2} bg="gray.100" children=
{`curl --request GET \\
--url 'https://api.revedy.io/getrecentnotes' \\
--header 'Authorization: Bearer YOUR_ACCESS_TOKEN'`} />
</Box>
                <Divider />
                <Box id="credentials">
                    <Heading as="h2" size="lg" mb={2}>Client Credentials</Heading>
                    <Button onClick={handleGetCredentialsClick} isLoading={loadingCredentials}>
                        Get Credentials
                    </Button>
                    {credentials && (
                        <>
                        <Text>Your Client ID: {credentials.clientId}</Text>
                        <Text>Your Client Secret: {credentials.clientSecret}</Text>
                        </>
                    )}
                </Box>
                <Divider />

                <Box id="endpoints">
                    <Heading as="h2" size="lg" mb={2}>Endpoints</Heading>
                </Box>
                <Box id="get-recent-notes">
                    <Heading as="h3" size="md" mb={2}>Get Recent Notes</Heading>
                    <Text fontSize="md" >
                        The <Code>/getrecentnotes</Code> endpoint retrieves the most recent notes created by the user, up to a limit of 30 notes. Each note includes a snippet, version information, coding status, and processing time.
                    </Text>
                    <Text fontSize="md" >
                        HTTP Method: <Code>GET</Code>
                    </Text>
                    <Text fontSize="md" >
                        URL: <Code>https://api.revedy.io/getrecentnotes</Code>
                    </Text>
                    <Text fontSize="md" >
                        Headers:
                    </Text>
                    <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
                    <Text fontSize="md" >
                        Response Format (pretty-printed):
                    </Text>
                    <Code p={2} bg="gray.100" whiteSpace="pre">
{`{
"notes": 
    [
        {
        "note_id": "unique_note_id",
        "note_snippet": "First 20 characters of the note...",
        "version_id": "version_identifier",
        "coding_status": "job_status",
        "id": "note_identifier",
        "process_time": "time_in_seconds"
        },
        // ... more notes
    ]
}`}
                    </Code>
                    <Text fontSize="md" >
                        This endpoint returns a JSON object containing an array of notes. If no notes are available, a sample note is created and returned in the response.
                    </Text>
                    <Text fontSize="md" >
                        Error Responses:
                    </Text>
                    <Code p={2} bg="gray.100" children={`401 Unauthorized - Access token is missing or invalid.`} />
                    <Code p={2} bg="gray.100" children={`500 Internal Server Error - An error occurred on the server.`} />
                    {/* Add more error responses as needed */}
                    </Box>
                    <Divider />



                    <Box id="encounter-analysis">
            <Heading as="h3" size="md" mb={2}>Encounter Analysis</Heading>
            <Text fontSize="md">
              The <Code>/v1/encounter-analysis</Code> endpoint is designed to process various types of data (audio, transcript, or external note) and perform analysis based on the specified analysis types. The process involves creating an encounter record, processing the source data, and performing the requested analysis (e.g., coding).
            </Text>
            <Text fontSize="md">
              HTTP Method: <Code>POST</Code>
            </Text>
            <Text fontSize="md">
              URL: <Code>https://api.revedy.io/v1/encounter-analysis</Code>
            </Text>
            <Text fontSize="md">
              Headers:
            </Text>
            <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
            <Code p={2} bg="gray.100" children={`Content-Type: multipart/form-data`} />
            <Text fontSize="md">
              Form Parameters:
            </Text>
            <Code p={2} bg="gray.100" whiteSpace="pre">
              {`encounter_id: Unique identifier for the encounter.
analysis_type: The type(s) of analysis to be performed (e.g., "coding"). 
audio: The audio file to be processed (optional).
external_note: The external note to be processed (optional).
transcript: The transcript to be processed (optional).`}
            </Code>
            <Text fontSize="md">
              Sample Request (Audio):
            </Text>
            <Code p={2} bg="gray.100" whiteSpace="pre">
              {`curl --request POST \\
--url 'https://api.revedy.io/v1/encounter-analysis' \\
--header 'Authorization: Bearer YOUR_ACCESS_TOKEN' \\
--header 'Content-Type: multipart/form-data' \\
--form 'encounter_id=ENC123' \\
--form 'analysis_type=coding' \\
--form 'audio=@/path/to/your/audiofile.mp3'`}
            </Code>
            <Text fontSize="md">
              Sample Request (External Note):
            </Text>
            <Code p={2} bg="gray.100" whiteSpace="pre">
              {`curl --request POST \\
--url 'https://api.revedy.io/v1/encounter-analysis' \\
--header 'Authorization: Bearer YOUR_ACCESS_TOKEN' \\
--header 'Content-Type: multipart/form-data' \\
--form 'encounter_id=ENC123' \\
--form 'analysis_type=coding' \\
--form 'external_note=Your external note here'`}
            </Code>
            <Text fontSize="md">
              Sample Request (Transcript):
            </Text>
            <Code p={2} bg="gray.100" whiteSpace="pre">
              {`curl --request POST \\
--url 'https://api.revedy.io/v1/encounter-analysis' \\
--header 'Authorization: Bearer YOUR_ACCESS_TOKEN' \\
--header 'Content-Type: multipart/form-data' \\
--form 'encounter_id=ENC123' \\
--form 'analysis_type=coding' \\
--form 'transcript=Your transcript here'`}
            </Code>
                              <Text fontSize="md">
                    Response Format (pretty-printed):
                  </Text>
                  <Code p={2} bg="gray.100" whiteSpace="pre">
  {`{
  "coding": {
    "api_call_durations": {
      "analyze_duration": 2.6700210571289062,
      "generate_note_duration": 0.2616300582885742,
      "transcribe_audio_duration": 10.819272994995117
    },
    "codes": {
      "job_id": 574,
      "note_id": 1716,
      "request_id": "1",
      "note": "The patient is a 55-year-old male presenting with a few weeks' history of neck and...",
      "structured_transcript": "**Patient:** I'm doing all right. I've been having this pain...",
      "transcribed_test": "okay I'm doing all right I've been having this pain in...",
      "results": {
        "cpt_em": {
          "code": "99204",
          "score": "moderate",
          "patienttype": "new",
          "risk":
          {
            "high":
            {
                "likelihood score": "10",
                "reader discussion":
                {
                    "text": "based on the note provided, there is no indication of drug therapy requiring intensive monitoring for toxicity. the plan involves prescribing a medrol dose pack and robaxin, which are commonly used medications with known side effects but do not typically require intensive monitoring for toxicity."
                }
            },
            "low":
            {},
            "moderate":
            {
                "likelihood score": "70",
                "reader discussion":
                {
                    "text": "based on the provided medical note, the patient is being managed for suspected herniated cervical disc with radiculopathy. the plan includes prescription drug management with medrol and robaxin, as well as diagnostic imaging with x-rays and potential mri. the patient's symptoms of weakness and radicular pain, along with the planned interventions, suggest a moderate risk of morbidity from additional diagnostic testing or treatment, especially considering the potential for serious complications associated with cervical disc herniation."
                }
            }
          },
          "problem type counts":
          {
              "4": 2
          },
          //additional data supporting the MDM determination
        },
        "icd10": {
          "M5412": {
            "diagnosis": {
              "texts": [
                {
                  "content": "the patient's symptoms and examination findings suggest a herniated ...",
                  "end": "start",
                  "start": "end"
                }
              ]
            },
            "evidence": {
              "texts": [
                {
                  "content": "the patient is a 55-year-old male ...",
                  "end": "start",
                  "start": "end"
                }
              ]
            },
            "match": {
              "explanation": "Based on the provided content, the ICD-10 code M54.12 (Radiculopathy, ...",
              "score": 100
            },
            "medication": {
              "texts": [
                {
                  "content": "prescribe a medrol dose pack for 7 days to manage inflammation.",
                  "end": "start",
                  "start": "end"
                }
              ]
            }
          }
        }
      }
    }
  }
}`}
</Code>
                  <Text fontSize="md">
                    This endpoint returns a JSON object containing the CPT and ICD-10 codes extracted from the generated note, along with the durations of each API call involved in the process.
                  </Text>
                  <Text fontSize="md">
                    Error Responses:
                  </Text>
                  <Code p={2} bg="gray.100" children={`400 Bad Request - No source data was included in the request.`} />
                  <Code p={2} bg="gray.100" children={`500 Internal Server Error - Transcription, note generation, or analysis failed.`} />
                </Box>










                    <Box id="generate-note-from-data">
                    <Heading as="h3" size="md" mb={2}>Generate Note from Data</Heading>
                        <Text fontSize="md" >
                            The <Code>/generate_note_from_data</Code> endpoint is designed to process various types of input data and generate a medical note. It supports multiple data types, including transcripts, clinician notes, chat history, additional context, and patient records. The endpoint also integrates with a workflow system that orchestrates calls to various services for code generation and validation.
                        </Text>
                        <Text fontSize="md" >
                            HTTP Method: <Code>POST</Code>
                        </Text>
                        <Text fontSize="md" >
                            URL: <Code>https://api.revedy.io/generate_note_from_data</Code>
                        </Text>
                        <Text fontSize="md" >
                            Headers:
                        </Text>
                        <Code p={2} bg="gray.100" children={`Authorization: Bearer YOUR_ACCESS_TOKEN`} />
                        <Code p={2} bg="gray.100" children={`Content-Type: application/json`} />
                        <Text fontSize="md" >
                            Request Body Format:
                        </Text>
                        <Code p={2} bg="gray.100" whiteSpace="pre">
{`{
    "sent_data": "data_to_process",
    "external_sent_data_id": "optional_external_id",
    "transcript": "optional_transcript_data",
    "clinician notes": "optional_clinician_notes",
    "chat_history": "optional_chat_history",
    "additional_context": "optional_additional_context",
    "patient_record": "optional_patient_record"
}`}
                        </Code>
                        <Text fontSize="md" >
                            Response Format (pretty-printed):
                        </Text>
                        <Code p={2} bg="gray.100" whiteSpace="pre">
{`{
    "generate_note_with_icd10_cpt_simple": {
        "data": "generated_note_data",
        "additional_info": "any_additional_information"
    },
// ... other workflow task results
}`}
                        </Code>
                        <Text fontSize="md" >
                            This endpoint returns a JSON object containing the results of the note generation workflow. The response includes the generated note along with any additional information from the workflow tasks.
                        </Text>
                        <Text fontSize="md" >
                            Error Responses:
                        </Text>
                        <Code p={2} bg="gray.100" children={`400 Bad Request - Client did not send sufficient data.`} />
                        <Code p={2} bg="gray.100" children={`401 Unauthorized - Access token is missing or invalid.`} />
                        <Code p={2} bg="gray.100" children={`500 Internal Server Error - An error occurred while running the workflow.`} />

</Box>

                
{/* Error Codes Section */}
<Box id="error-codes">
<Heading as="h2" size="lg" mb={2}>Error Codes</Heading>
<Text fontSize="md">
    The API uses the following error codes:
</Text>
<Container p={2} bg="gray.50" borderRadius="md">
    <Text fontSize="sm"><Code children="no_file_part" /> No file was included in the request.</Text>
    <Text fontSize="sm"><Code children="error generating event id" /> There was an error generating the event ID.</Text>
    <Text fontSize="sm"><Code children="transcription_failed" /> The transcription of the audio file failed.</Text>
    {/* Add more error codes as needed */}
</Container>
</Box>
                
                <Divider />

                {/* Rate Limits Section */}
                <Box id="rate-limits">
                <Heading as="h2" size="lg" mb={2}>Rate Limits</Heading>
                <Text fontSize="md">
                    The API limits the number of requests that can be made to prevent abuse. If you exceed these limits, you will receive a <Code children="429" /> Too Many Requests response.
                </Text>
                </Box>
                {/* Add more sections as needed */}

























            </VStack>
            </GridItem>



        {/* Footer */}
        <GridItem area="footer" bg='white' borderTop='1px' borderColor='gray.200' p={4}>
          {/* Footer content */}
        </GridItem>
      </Grid>
    </Box>
  );
}

export default APIDocs;