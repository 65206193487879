import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Box, Grid, GridItem } from "@chakra-ui/react";
import HeaderMenus from './HeaderMenus';
import { handleCaseList } from './caseService'; // adjust the import based on your project structure
import { callApi } from '../callApi'; 
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../components/AppContext';

function CaseServiceTable({ handleCaseButtonClick }) {
  const [cases, setCases] = useState([]);
  const navigate = useNavigate();
  const { accessToken, loading, appState, setAppState } = useContext(AppContext);


  useEffect(() => {
    //check if we want to include only cases with coding issues - there would be i=1 in the url
    const urlParams = new URLSearchParams(window.location.search);
    const codingIssues = urlParams.get('i');
    const searchTerm = ""
    const perIssue = 1;
    const caseId = "";
    const fetchCases = async () => {
      const cases = await handleCaseList(callApi, accessToken, caseId, codingIssues, searchTerm, perIssue);
      setCases(cases);
    };

    fetchCases();
  }, []);

  const data = React.useMemo(() => cases, [cases]);

  function formatDateWithTimeZone(value) {
    if (value === null || value === undefined || value === '' || value === 'None') {
      return 'N/A';
    }
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date(value);
    const options = {
      timeZone,
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short'
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }


  const columns = React.useMemo(
    () => [
      {
        Header: 'Patient ID',
        accessor: 'external_patient_id',
      },
      {
        Header: 'For Review',
        accessor: 'coding_issues',
      },
      {
        Header: 'Procedure Date',
        accessor: 'procedure_date',
        Cell: ({ value }) => formatDateWithTimeZone(value),
      },
      {
        Header: 'Last Name',
        accessor: 'patient_last_name',
      },
      {
        Header: 'Secondary Patient ID',
        accessor: 'external_patient_id_secondary',
      },
      {
        Header: 'Cadwell Case ID',
        accessor: 'case_id',
      },
      {
        Header: 'Case Name',
        accessor: 'case_name',
      },
      {
        Header: 'Processing Date',
        accessor: 'create_date',
        Cell: ({ value }) => formatDateWithTimeZone(value),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);


  function convertToCSV(data) {
    const headers = columns.map(col => col.Header).join(',');
    const rows = data.map(row =>
      columns.map(col => {
        const cellValue = row[col.accessor];
        return `"${cellValue}"`; // Handle values that contain commas
      }).join(',')
    );
    return [headers, ...rows].join('\n');
  }
  
  function downloadCSV(csvString, filename) {
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  function handleExportToCSV() {
    const csvString = convertToCSV(cases);
    downloadCSV(csvString, 'RevedyProcessReport.csv');
  }
  


  return (
    <Box textAlign="center" fontSize="xl">
      <Grid
        templateAreas={`"header header"    
                        "main main"
                        "form form"
                        "footer footer"`}
        gridTemplateRows={'60px auto 1fr 60px'}
        gridTemplateColumns={'1fr 1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontSize='14px'
        fontWeight='medium'
      >
        <HeaderMenus />
  
        <GridItem bg='gray.50' padding={4} area={'main'} verticalAlign={"bottom"}>
        <Box display="flex" justifyContent="flex-end" marginBottom="1em">
          <a href="/ionmtable" rel="noopener noreferrer" style={{ marginRight: '10px' }}>All Results</a>
          <a href="/ionmtable?i=1" rel="noopener noreferrer" style={{ marginRight: '10px' }}>For Review</a>
          <button onClick={handleExportToCSV}>Export to CSV</button>
        </Box>
            <Box overflowX="auto" display="flex" justifyContent="center"> {/* Center the table */}
              <table {...getTableProps()} style={{ width: '100%', borderSpacing: '0' }}> {/* Make the table a little narrower */}
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          style={{
                            borderBottom: 'solid 3px red',
                            background: 'aliceblue',
                            color: 'black',
                            fontWeight: 'bold',
                            width: '20%', // Make the columns a little wider
                          }}
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map(row => {
                    prepareRow(row);
                    return (


                        <tr {...row.getRowProps()} 
                        onClick={() => {
                          const url = `/ionmform?caseid=${row.original.case_id}`;
                          window.open(url, '_blank');
                      }}
                      >
                        {row.cells.map(cell => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              style={{
                                padding: '10px',
                                border: 'solid 1px gray',
                                background: 'white', // Make the cell color white
                              }}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Box>
        </GridItem>
      </Grid>
    </Box>
  )

}

export default CaseServiceTable;