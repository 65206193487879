import React from 'react';
import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import Chat from './Chat';
import HeaderMenus from './HeaderMenus';

function HomePage() {
    const SlidingText = ({ text, delay }) => {
        const slideInStyles = {
          animationDelay: `${delay}s`,
        };
      
        return (
          <Text fontSize={"14px"} mb={4} className="sliding-text" style={slideInStyles}>
            {text}
          </Text>
        );
    };

    return (
        <Box textAlign="center" fontSize="xl" bg="white">
<Grid
  templateAreas={`"header header"
                  "slider main"
                  "footer footer"`}
  gridTemplateRows={'60px 1fr 30px'}
  gridTemplateColumns={'200px 1fr'} // Adjust the width of the sliding text column as needed
  h='100vh'
  gap='1'
  color='blackAlpha.800'
  fontWeight='bold'
>
  {/* Header with menu */}
  <GridItem area="header">
    <HeaderMenus />
  </GridItem>

  {/* Sliding text area */}
  <GridItem area="slider" bg='white' p={3}>
    <SlidingText text="Improve HCC capture with Code Notes" delay={0} />
    <SlidingText text="Interactive coding support with Code Notes" delay={2} />
    <SlidingText text="Generate notes with Scribe Services " delay={4} />
    <SlidingText text="Create notes from orders with Create Notes " delay={6} />
    <SlidingText text="Compliant and secure AI access for all with Healthcare AI Chat" delay={8} />
    <SlidingText text="Automatically code IONM from Cadwell, Natus, and Medtronic" delay={10} />
    <SlidingText text="HIPAA and HITRUST compliant" delay={12} />
    <SlidingText text="Extensive configuration options for large organizations" delay={14} />
  </GridItem>

  {/* Main content area with Chat component */}
  <GridItem area="main" bg='white' p={3} textAlign="left" paddingLeft={40}>

    <Chat 
      chatOnly={true}
      contextType={'revedy'} 
      contextData={'revedy_welcome'} 
      contextPersona={'revedy_welcome'}
      useOptions={true}
      maintainContext={true}
    />
  </GridItem>

  {/* Footer */}
  <GridItem area="footer" bg='white' borderTop='1px' borderColor='gray.200' p={4}>
    {/* Footer content */}
  </GridItem>
</Grid>
        </Box>
    );
}

export default HomePage;