import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { callApi } from '../callApi'; 
import { AppContext } from '../components/AppContext';
import { useAuth0 } from "@auth0/auth0-react";
import { FeatureFlagsProvider } from './FeatureFlags';  // Import your FeatureFlagsProvider

const BackendCheck = ({ children }) => {
  const { isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();
  const [isChecking, setIsChecking] = useState(true);
  const [isApproved, setIsApproved] = useState(false);
  const [featureFlags, setFeatureFlags] = useState({});  // Add a state for the feature flags
  const { accessToken, loading, appState, setAppState } = useContext(AppContext);


  useEffect(() => {
    const checkApproval = async () => {
      if (isAuthenticated && !loading && accessToken) {
        try {
          const response = await callApi('/check_approved', accessToken, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
          });
          const data = await response.json();
          setIsChecking(false);

          if (response.status === 200) {
            setIsApproved(true);
            setFeatureFlags(data.feature_flags);  // Store the feature flags in the local state
          } else if (response.status === 403 && data.error_code === 'terms_not_agreed') {
            navigate('/terms');
          } else {
            sessionStorage.setItem('error', data.error_code);
            logout({ returnTo: `${window.location.origin}/login` }); 
            setIsApproved(false);
          }
      } catch (error) {
          setIsChecking(false);
          setIsApproved(false);
        }
      }
    };
    checkApproval();
  }, [navigate, isAuthenticated, accessToken, loading, isChecking]);

  if (isChecking) {
    return <div>Loading...</div>;
  }

  if (!isApproved) {
    return null;
  }

  // Wrap the children with the FeatureFlagsProvider
  return (
    <FeatureFlagsProvider value={featureFlags}>
      {children}
    </FeatureFlagsProvider>
  );
};

export default BackendCheck;