import React, { useState, useEffect, useContext} from 'react';
import { useToast } from '@chakra-ui/react';    
import { callApi } from '../callApi'; 
import ReactMarkdown from 'react-markdown';
import { ChakraProvider, Box, Grid, GridItem, 
    Flex, Image, VStack, Text, Textarea, Button, Input, FormLabel, Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Spinner,
    Link,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,

 } from "@chakra-ui/react";
import { extendTheme } from '@chakra-ui/react';
import AccountMenu from '../account';
import CaseServiceList from './CaseServiceList';
import HeaderMenus from './HeaderMenus';
import { setIn } from 'formik';
import ChatToggle from './ChatToggle'; // Adjust the import path as necessary
import AudioVolumeVisualizer from './AudioVolumeVisualizer'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';
import { AppContext } from './AppContext';

const colors = {
    brand: {
      900: '#8ECAE6', //light blue
      800: '#219EBC', //blue
      700: '#023047', //dark blue
      600: '#FFB703', //yellow
      500: '#FB8500' //orange
    },
  };
  
  // Extend the theme
  const theme = extendTheme({ colors });


function FullCode() {
  const [fileUploading, setFileUploading] = useState(false);
  const { accessToken, loading, appState, setAppState } = useContext(AppContext);
  const toast = useToast();
  const [codeResults, setCodeResults] = useState(false);
  const ProcessingSpeed = {
    NORMAL: 'normal',
    FAST: 'fast',
    FASTEST: 'fastest'
  };
  const [transcript, setTranscript] = useState('');

  

  const resetFileInput = (fileInputId) => {
    const fileInput = document.getElementById(fileInputId);
    if (fileInput) {
      fileInput.value = '';
    }
  };


  const handleFileUpload = async (event, processingSpeed) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('view', 'short');
      formData.append('processing_speed', processingSpeed);
      await processUpload(formData);
      resetFileInput(event.target.id);
    }
  };

  const handleTranscriptSubmit = async (processingSpeed) => {
    if (transcript) {
      const formData = new FormData();
      formData.append('transcript', transcript);
      formData.append('view', 'short');
      formData.append('processing_speed', processingSpeed);
      await processUpload(formData);
    }
  };

    const processUpload = async (formData) => {
      setFileUploading(true);
      setCodeResults(null);
      try {
        const response = await callApi('/code_note_from_audio', accessToken, {
          method: 'POST',
          body: formData,
        });

        const data = await response.json();
        let noteUrl;
        let durationElement;
        let durationElements;
        //check to see if data.api_call_durations is defined
        if (!data.api_call_durations) {
          //just set durationElement to whatever the data is but wrap it in a jsx element
          durationElement = <Text>{JSON.stringify(data)}</Text>;
          noteUrl = data.job_id;
        }
        else {
          const durations = data.api_call_durations;

          const durationDisplayNames = {
              analyze_duration: 'Analysis Time',
              generate_note_duration: 'Note Generation Time',
              transcribe_audio_duration: 'Transcription Time'
            };
            
            
            durationElements = Object.keys(durations)
              .filter(key => key in durationDisplayNames) // Exclude keys not in the mapping
              .map((key) => {
                const roundedValue = Math.round(durations[key]);
                return (
                  <Text key={key}>
                    {`${durationDisplayNames[key]}: ${roundedValue} second${roundedValue !== 1 ? 's' : ''}`}
                  </Text>
                );
              });
          
          noteUrl = data.note_url;
        }
        const formattedResults = (
            <Box>
              <VStack align="start">
                {durationElements}
                <Text as="div">
                  Note URL: <Link href={noteUrl} isExternal>
                              {noteUrl}
                            </Link>
                </Text>
              </VStack>
            </Box>
          );
          setCodeResults(formattedResults);


        toast({
          title: 'Upload successful.',
          description: "File has been uploaded and is being processed.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error sending audio data:', error);
        toast({
          title: 'Upload failed.',
          description: "There was an error processing your file.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setFileUploading(false);
      }
    };

  return (

<Box textAlign="center" fontSize="xl">
<Grid
templateAreas={`"header header"    
                "main results"
                "footer footer"`}
gridTemplateRows={'60px 1fr 60px'}
gridTemplateColumns={'1fr 1fr'}
h='100%'
gap='2'
color='blackAlpha.800'
fontWeight='bold'
>
        {/* Header with menu */}
            <HeaderMenus />

    <GridItem bg='gray.50' padding={4} area={'main'}>
        <Box textAlign="center" fontSize="xl">

        <FormLabel htmlFor="audio-upload-fast">Upload Audio File (Normal)</FormLabel>
        <Input
        id="audio-upload-fast"
        type="file"
        accept="audio/*"
        onChange={(event) => handleFileUpload(event, ProcessingSpeed.FAST)}
        disabled={fileUploading}
        />


        
        {fileUploading && <p>Uploading...</p>}
        </Box>
        <Textarea
  id="transcript-input"
  placeholder="Paste your transcript here..."
  value={transcript}
  onChange={(event) => setTranscript(event.target.value)}
  disabled={fileUploading}
/>
<Button
  onClick={() => handleTranscriptSubmit(ProcessingSpeed.FAST)}
  disabled={fileUploading || !transcript}
>
  Send Transcript (Normal)
  </Button>

    </GridItem>

    <GridItem bg='gray.50' padding={4} area={'results'} alignContent={"left"}>

{codeResults ? (
<Box position="relative" display="inline-block" alignContent={"left"} textalign={"left"} 
fontSize="sm" color="grey.700" padding={4}>
{codeResults}
</Box>
) : (
         <Text fontSize="md" color="grey.700" align="left">
            loading...
        </Text>
         
)}
    

    </GridItem>


    <GridItem
      bg='white'
      borderTop='1px'
      borderColor='brand.200'
      paddingY={4}
      paddingX={6}
      area={'footer'}
    >
      <Flex justifyContent="center" alignItems="center">
        <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
        <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
          &copy; {new Date().getFullYear()}
        </Text>
      </Flex>
    </GridItem>
  </Grid>
</Box>




  );
}

export default FullCode;