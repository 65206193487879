import React, { useState, useContext } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { callApi } from '../callApi';
import { AppContext } from './AppContext';
import { useNavigate } from 'react-router-dom';

const StripeCheckout = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { accessToken } = useContext(AppContext);
  const navigate = useNavigate();

  const handleCheckout = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await callApi('/checkout_create_session', accessToken, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product_id: 'prod_PnXVovmaqfRYVB',  // Replace with your actual Product ID
          price_id: 'price_1OxwQUK50DNkAyBz0eMqh9SS',  // Replace with your actual Price ID
        }),
      });

      const data = await response.json();
      const sessionId = data.session_id;

      // Redirect to Stripe Checkout
      const stripe = window.Stripe('pk_test_51OxYPUK50DNkAyBzbZwnSqAYqOxTi2xKqXOU74sooWN4dQ9IQrymRKerxQq30QIXTdm1bj5PIgkLgKTvNGogWCM200eAmgc9wj');
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      setError('An error occurred.: ' + error.message);
    }

    setLoading(false);
  };

  return (
    <Box>
      <Text>Stripe Checkout Example</Text>
      {error && <Text color="red.500">{error}</Text>}
      <Button onClick={handleCheckout} isLoading={loading}>
        Checkout
      </Button>
    </Box>
  );
};

export default StripeCheckout;