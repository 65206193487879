// themes.js
import { extendTheme } from "@chakra-ui/react";

export const theosTheme = extendTheme({
  colors: {
    menu_text_primary: "#000000",
    menu_text_secondary: "#153e75",
    header_background: "#ffffff",
    menu_background: "#4a5568",
    // Add more custom colors here
  },
  // Add more theme customizations here
});

export const nmaTheme = extendTheme({
  colors: {
    menu_text_primary: "#000000",
    menu_text_secondary: "#153e75",
    header_background: "#ffffff",
    menu_background: "#4a5568",
    // Add more custom colors here
  },
  // Add more theme customizations here
});

export const revedyTheme = extendTheme({
  colors: {
    menu_text_primary: "#000000",
    menu_text_secondary: "#153e75",
    header_background: "#ffffff",
    menu_background: "#4a5568",
    // Add more custom colors here
  },
  // Add more theme customizations here
});

export const revedyThemeOLD = extendTheme({
  colors: {
    menu_text_primary: "#ffffff",
    menu_text_secondary: "#2d3748",
    header_background: "#4a5568",
    menu_background: "#2a69ac",
    // Add more custom colors here
  },
  // Add more theme customizations here
});