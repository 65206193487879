import React, { useState, useEffect, useContext } from 'react';
import { callApi } from '../callApi'; 
import ReactMarkdown from 'react-markdown';
import { ChakraProvider, Box, Grid, GridItem, 
    Flex, Image, VStack, Text, Textarea, Button, Input, FormLabel, Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Spinner,

 } from "@chakra-ui/react";
import { extendTheme } from '@chakra-ui/react';
import AccountMenu from '../account';
import CaseServiceList from './CaseServiceList';
import HeaderMenus from './HeaderMenus';
import { setIn } from 'formik';
import ChatToggle from './ChatToggle'; // Adjust the import path as necessary
import AudioVolumeVisualizer from './AudioVolumeVisualizer'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';
import { AppContext } from './AppContext';

const colors = {
    brand: {
      900: '#8ECAE6', //light blue
      800: '#219EBC', //blue
      700: '#023047', //dark blue
      600: '#FFB703', //yellow
      500: '#FB8500' //orange
    },
  };
  
  // Extend the theme
  const theme = extendTheme({ colors });

  
function GenerateTranscript() {

    const [recordingState, setRecordingState] = useState('not recording');
    const [recordingFunctions, setRecordingFunctions] = useState(null);
    const [audioData, setAudioData] = useState(null);
    const [transcribedText, setTranscribedText] = useState('');
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [mediaStream, setMediaStream] = useState(null); // Add this state to hold the media stream
    const [transcribedTextID, setTranscribedTextID] = useState('');
    const [noteText, setNoteText] = useState('');
    const [noteId, setNoteId] = useState('');
    const [versionId, setVersionId] = useState('');
    const [structuredTranscript, setStructuredTranscript] = useState('');
    const navigate = useNavigate();
    const { accessToken, loading, appState, setAppState } = useContext(AppContext);
    const [sampleText, setSampleText] = useState('');
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const [fileUploading, setFileUploading] = useState(false);



    const handleNavigateAndSetContext = () => {
      // Update the AppContext with the current state of input fields
      setAppState(prevState => ({
        ...prevState,
        transcript: structuredTranscript,
      }));
    
      // Navigate to the /generate page
      navigate('/generate');
    };

  
    useEffect(() => {
        let mediaRecorder = null;

        const startRecording = async () => {
            setTranscribedText('');
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setMediaStream(stream); // Save the stream to state
            const audioChunks = [];
            let options = {};

            // Check if the browser supports the audio/mp4 MIME type for MediaRecorder
            if (MediaRecorder.isTypeSupported('audio/mp4')) {
                options = { mimeType: 'audio/mp4' };
            } else {
                // Fallback to the default MIME type or another supported type
                console.warn('M4A format (audio/mp4) is not supported by this browser. Using default MIME type.');
            }

            mediaRecorder = new MediaRecorder(stream, options);
      
            mediaRecorder = new MediaRecorder(stream);
            //console.log("mediaRecorder running: ", mediaRecorder.state);
            setSampleText(null);
            setStructuredTranscript(null);
            setTranscribedText(null);
            setTranscribedTextID(null);
            

            mediaRecorder.ondataavailable = event => {
                audioChunks.push(event.data);
                //console.log("audioChunks: ", audioChunks);
            };
            
            mediaRecorder.start(10000);

            mediaRecorder.onstop = () => {
                const audioBlob = new Blob(audioChunks);
                setAudioData(audioBlob);
                setRecordingState('transcribing');
                sendAudioData(audioBlob);
            };
        
            //mediaRecorder.start(10000);
            setRecordingState('recording');
            //console.log("mediaRecorder running end: ", mediaRecorder.state);
            
        };
        
        const stopRecording = () => {
            // Stop the MediaRecorder
            if (mediaRecorder) {
                mediaRecorder.stop();
            }
            else {
                //console.log("mediaRecorder is null");
            }
        };
        
        setRecordingFunctions({ startRecording, stopRecording });
    }, []);

    
    const handleTranscriptNoteClick = async (transcribedTextID) => {
      const response = await callApi('/generate_note_from_transcript', accessToken, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            transcript: transcribedText,
            transcript_id: transcribedTextID,
          }),
      });
      //console.log("compare response: ", response);
      // Parse the response as JSON
      const data = await response.json();
      const noteText = data.note;
      const noteId = data.note_id;
      const versionId = data.version_id;
      setNoteText(noteText);
      setNoteId(noteId);
      setVersionId(versionId);

  };

  const getSampleTranscript = async () => {
    const response = await callApi('/get_sample_transcript', accessToken, {
        method: 'POST',
        body: "",
    });
  
      const data = await response.json();
      //console.log(data);
      setTranscribedText(data.transcribed_text);
      setTranscribedTextID(data.transcription_id);
      setStructuredTranscript(data.structured_transcript);
      setRecordingState('not recording');
      // set again with markdown formatting
      setSampleText("Audio from  Western Michigan University Homer Stryker M.D. School of Medicine:\n\n https://www.youtube.com/watch?v=S4wWClQhZaA \n\n  For real time transcription, play this audio in a separate window and click 'Start Recording' above.");  

    };

  const handleCreateNoteClick = (noteText) => {
    setAppState(prevState => ({
      ...prevState,
      generatedNote: true, 
      generatedNoteText: noteText, 
    }));
    navigate('/create-note?create=true'); 
  };

  const handleFileUpload = async (event) => {
  
    const file = event.target.files[0];
    if (file) {
      setFileUploading(true);
      setRecordingState('');
      setTranscribedText('');
        sendAudioData(file).then(() => {
        //setRecordingState('not recording'); // Reset the recording state once the file is sent
      }).catch((error) => {
        console.error('Error sending audio data:', error);
        //setRecordingState('not recording'); // Reset the recording state in case of an error
      });
    }
  };

    const sendAudioData = async (audioData) => {
      const formData = new FormData();
      formData.append('file', audioData);
    
      const response = await callApi('/transcribe_audio', accessToken, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'multipart/form-data' is not needed here; the browser sets it automatically with the correct boundary when using FormData
        },
        body: formData,
      });

        const data = await response.json();
        //console.log(data);
        setTranscribedText(data.transcribed_text);
        setTranscribedTextID(data.transcription_id);
        setStructuredTranscript(data.structured_transcript);
        setRecordingState('not recording');
    };

    return (
        <Box textAlign="center" fontSize="xl" bg="white">
        <Grid
        templateAreas={`"header header"    
                        "main results"
                        "form form"
                        "footer footer"`}
        gridTemplateRows={'60px auto 1fr 60px'}
        gridTemplateColumns={'1fr 1fr'}
        h='100%'
        gap='2'
        color='blackAlpha.800'
        fontWeight='bold'
        >
           
            <HeaderMenus />
        
            <GridItem bg='gray.50' padding={4} area={'main'}>
            <Flex justifyContent="left" alignItems="left" padding="3">
              <VStack>
                    <Text>
                      <Button variant="link" onClick={getSampleTranscript}>
                        Get Sample
                    </Button>
                    </Text>
                    <Box>
                            <FormLabel htmlFor="audio-upload">Upload Audio File</FormLabel>
                            <Input
                              id="audio-upload"
                              type="file"
                              accept="audio/*"
                              onChange={handleFileUpload}
                              disabled={recordingState === 'recording'}
                            />
                          </Box>
                      </VStack>
 
                        <Flex justifyContent="space-between" alignItems="center" padding="4">
                          <Box>
                            <VStack>
                            {isChrome && (
                              <>
                                <Button
                                  onClick={recordingState === 'not recording' ? recordingFunctions?.startRecording : null}
                                  isDisabled={recordingState !== 'not recording' }
                                >
                                  Start Recording
                                </Button>
                                <Button
                                  onClick={recordingState === 'recording' ? recordingFunctions?.stopRecording : null}
                                  isDisabled={recordingState !== 'recording'}
                                >
                                  Stop Recording
                                </Button>
                                <Box marginBottom={4} marginRight={4}>
                                  {recordingState === 'recording' && mediaStream && (
                                          <AudioVolumeVisualizer stream={mediaStream} />
                                      )}
                                  </Box>
                              </>
                            )}
                            {!isChrome && (
                              <Text>
                                The audio recording feature is not available. Please use the Chrome browser.
                              </Text>
                            )}
                            </VStack>
                          </Box>
                        </Flex>



                    
                    
                      
                    </Flex>

                        <Text 
                            bgColor={'white'} 
                            value={transcribedText} 
                            onChange={e => setTranscribedText(e.target.value)} 
                            rows="4" 
                        >
                            {recordingState === 'not recording' && !transcribedText && !fileUploading ? "Transcript will appear here" :
                              recordingState === 'recording' && !transcribedText && !fileUploading ? "Recording..." :
                              !transcribedText && !fileUploading ? "Transcribing..." :
                              fileUploading && !transcribedText ? "Processing file..." : 
                              null
                            }
                            </Text>
                            {sampleText && (
                              <Box marginLeft="4px" className="markdown-content" textAlign="left">
                                            <ReactMarkdown>
                                              {sampleText}
                                            </ReactMarkdown>
                                          </Box>
                            )}

{transcribedText && transcribedTextID && (
  <Text fontSize="md" textAlign="left">
    <Button 
      onClick={handleNavigateAndSetContext}
      color="blue.500"
      variant="link"
      textDecoration="underline"
      cursor="pointer"
    >
      Full Note Generation
    </Button>
  </Text>
)}

{transcribedText && transcribedTextID && (
  <Text textAlign="left">
    <Button
      onClick={() => handleTranscriptNoteClick(transcribedTextID)}
      color="blue.500"
      variant="link"
      textDecoration="underline"
      cursor="pointer"
    >
      Simple Note Generation
    </Button>
  </Text>
)}
<br/>

                           {structuredTranscript && (
                              <Box marginLeft="4px" className="markdown-content" textAlign="left">
                              <ReactMarkdown>
                                {structuredTranscript.replace(/```markdown|\n```/g, '').replace(/\n/g, '\n\n  ')}
                              </ReactMarkdown>
                            </Box>
                              )
                            }
                            {transcribedText &&  (
                              <Box marginLeft="4px" className="markdown-content" textAlign="left">
                                <Text>Raw transcript:
                            {transcribedText}
                            </Text>
                            </Box>
                            )}

                      </GridItem>

            <GridItem bg='gray.50' padding={4} area={'results'}>
            
            <Tabs>
  <TabList>
    <Tab>Note</Tab>
    <Tab>Patient</Tab>
    <Tab>ICD-10</Tab>
    <Tab>CPT</Tab>
  </TabList>
  <TabPanels>
    <TabPanel>
        {noteText && (          
            <div>
                <button
                    onClick={() => handleCreateNoteClick(noteText)}
                    style={{ color: 'blue', backgroundColor: 'transparent', border: 'none', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                        Click here to code this note
                </button>
                <Box marginLeft="4px" className="markdown-content" textAlign="left">
                  <ReactMarkdown>
                        {noteText}
                    </ReactMarkdown>
                </Box>
            </div>
        )}
</TabPanel>
<TabPanel>

</TabPanel>
<TabPanel>
    </TabPanel>
    </TabPanels>
    </Tabs>
            

            </GridItem>


            <GridItem
              bg='white'
              borderTop='1px'
              borderColor='brand.200'
              paddingY={4}
              paddingX={6}
              area={'footer'}
            >
              <Flex justifyContent="center" alignItems="center">
                <Image src="/images/revedy_logo.png" alt="Revedy" height="15px" />
                <Text fontSize="sm" color="3e81c3" textAlign="center" marginLeft={2}>
                  &copy; {new Date().getFullYear()}
                </Text>
              </Flex>
            </GridItem>
          </Grid>
          <ChatToggle
            contextType={'revedy'}
            contextData={'revedy_scribe_services'}
            contextPersona={'revedy_scribe_services'}
            firstMessage={"Hi, what can I do on this page?"}
            useOptions={false}
            maintainContext={true}
            contextMode={'popup'}
        />

        </Box>
      );
    };        

export default GenerateTranscript;