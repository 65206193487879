import { ChakraProvider, Box, Button, Text, Grid, GridItem, Image, Flex, VStack, Stack, Input, Alert, AlertIcon, Center } from '@chakra-ui/react';
import { useAuth0 } from "@auth0/auth0-react";
import { extendTheme } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import AccountMenu from './account';
import errorMessages from './errorMessages'; // Adjust the path based on your file structure
import HeaderMenus from './components/HeaderMenus';
import { MenuVersionDefault } from './components/HeaderMenus';


// Define your color palette
const colors = {
  brand: {
    900: '#8ECAE6', //light blue
    800: '#219EBC', //blue
    700: '#023047', //dark blue
    600: '#FFB703', //yellow
    500: '#FB8500' //orange
  },
};

// Extend the theme
const theme = extendTheme({ colors });

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [message, setMessage] = useState(null);
  const errorCode = sessionStorage.getItem('error');
  console.log("error code is: " + errorCode);
  let errorMessage = null;
  if (errorCode && errorMessages[errorCode]) {
    errorMessage = errorMessages[errorCode];
    //clear the error code
    sessionStorage.removeItem('error');
  }
  else if (errorCode) {
    errorMessage = 'An error occured and our team has been alerted. Please contact us at support@revedy.io for additional assistance.';
  }

  const SlidingText = ({ text, delay }) => {
    const slideInStyles = {
      animationDelay: `${delay}s`,
    };
  
    return (
      <Text fontSize={"14px"} mb={4} className="sliding-text" style={slideInStyles}>
        {text}
      </Text>
    );
};


  const handleSignup = () => {
    if (!process.env.REACT_APP_REVEDY_API_HOST) {
      console.error('REACT_APP_REVEDY_API_HOST is not set');
      // Handle the error appropriately.
      return;
    }
  
    loginWithRedirect({
      authorizationParams: {
        'screen_hint': 'signup',
      },
      appState: { returnTo: `${window.location.origin}/home` },
      audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
      scope: "read:current_user",
    });
  };

  const handleLogin = () => {
    if (!process.env.REACT_APP_REVEDY_API_HOST) {
      console.error('REACT_APP_REVEDY_API_HOST is not set');
      // Handle the error appropriately.
      return;
    }

    loginWithRedirect({
      appState: { returnTo: `${window.location.origin}/home` },
      audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
      scope: "read:current_user",
    });
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  });

  return (    
        <Box textAlign="center" fontSize="xl" bg="white">
          <Grid
            templateAreas={`"header header"
                            "slider main"
                            "footer footer"`}
            gridTemplateRows={'60px 1fr 30px'}
            gridTemplateColumns={'200px 1fr'} // Adjust the width of the sliding text column as needed
            h='100vh'
            gap='1'
            color='blackAlpha.800'
            fontWeight='bold'
          >
            {/* Header with menu */}
            <GridItem area="header">
          <MenuVersionDefault />

            </GridItem>

            {/* Sliding text area */}
            <GridItem area="slider" bg='white' p={3}>
              <SlidingText text="Improve HCC capture with Code Notes" delay={0} />
              <SlidingText text="Interactive coding support with Code Notes" delay={2} />
              <SlidingText text="Generate notes with Scribe Services " delay={4} />
              <SlidingText text="Create notes from orders with Create Notes " delay={6} />
              <SlidingText text="Compliant and secure AI access for all with Healthcare AI Chat" delay={8} />
              <SlidingText text="Automatically code IONM from Cadwell, Natus, and Medtronic" delay={10} />
              <SlidingText text="HIPAA and HITRUST compliant" delay={12} />
              <SlidingText text="Extensive configuration options for large organizations" delay={14} />
            </GridItem>

            {/* Main content area with Chat component */}
            <GridItem 
              area="main" 
              bg='white' 
              p={3} 
              textAlign="left" 
              paddingLeft={30} 
              alignContent={"left"}
            >

                  <VStack spacing={6} align="start">
                    <Stack spacing={3} direction="column" align="left">
                      <Text fontSize="lg" marginBottom="2" textAlign="left">
                      Welcome to Revedy. We help clinicians and administrators enjoy their jobs more.<br/>
                        Click here for immediate access (all we need is your email):
                      </Text>
                      <Button
                        size="lg" 
                        variant="solid"
                        colorScheme="blue"
                        onClick={handleLogin}
                        _hover={{ transform: 'scale(1.05)', boxShadow: 'md' }}
                        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                      >
                        Get Started or Login
                      </Button>
                    </Stack>
                    <br/>
                    
                    <iframe 
                      width="669" 
                      height="473" 
                      src="https://www.youtube.com/embed/-ggCu1aFwNU?si=LymP3h_KYavrqR6H&controls=0&modestbranding=1&rel=0&showinfo=0&autoplay=0&loop=1" 
                      title="Revedy Overview" 
                      frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                      style={{ border: '1px solid #999' }} // Adjust the border style as needed
                      allowfullscreen>
                    </iframe>
                  </VStack>


          
          
            </GridItem>

            {/* Footer */}
            <GridItem area="footer" bg='white' borderTop='1px' borderColor='gray.200' p={4}>
              {/* Footer content */}
            </GridItem>
          </Grid>
        </Box>
  );
};

export default LoginPage;